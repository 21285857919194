<template lang="pug">
div
  Form(:data="worker", :isSubmitting="isSubmitting", :isPCUploadVisible="true", @submit="create")
</template>

<script>
import Form from '../shared/Form.vue'
import useWorkerAdd from './useWorkerAdd'

export default {
  components: {
    Form,
  },

  setup() {
    const { create, worker, isSubmitting } = useWorkerAdd()

    return {
      worker,
      create,
      isSubmitting,
    }
  },
}
</script>
