<template lang="pug">
b-card
  validation-observer(ref="form", tag="form")
    b-form
      b-row
        // Name
        b-col(md="6")
          b-form-group(:label="$t('label.Name')", :label-for="$t('label.name')", description="")
            validation-provider(:name="$t('label.Name')", rules="required", v-slot="{ errors }")
              b-form-input#name(v-model="data.name", trim, :placeholder="$t('placeholder.name')")
              small(class="text-danger") {{ errors[0] }}
        // Email
        b-col(md="6")
          b-form-group(:label="$t('label.Email')", :label-for="$t('label.email')", description="")
            validation-provider(:name="$t('label.Email')", rules="email", v-slot="{ errors }")
              b-form-input#email(v-model="data.email", trim, :placeholder="$t('placeholder.email')")
              small(class="text-danger") {{ errors[0] }}
      b-row
        b-col(:md="isPCUploadVisible ? '6' : '12'")
          // Locations
          b-form-group(
            :label="$t('label.Locations')",
            :label-for="$t('label.location')",
            :description="$t('message.worker_locations')"
          )
            v-select#location(
              v-model="data.locations",
              :reduce="(locations) => locations.value",
              :multiple="true",
              label="title",
              :options="locations",
              :placeholder="$t('placeholder.locations')"
            )
        b-col(v-if="isPCUploadVisible", md="6")
          // Automatic P.C. Upload
          b-form-group(
            :label="$t('label.automatic_pc_upload')",
            :label-for="$t('label.automatic_pc_upload')",
            :description="$t('message.enable_automatic_pc_upload')"
          )
            v-select#pcupload(
              v-model="data.isPCUploadEnabled",
              :reduce="(options) => options.value",
              label="title",
              :options="[{ value: true, title: $t('message.yes') }, { value: false, title: $t('message.no') }]",
            )
      b-row
        // Roles
        b-col(md="6")
          b-form-group(
            :label="$t('label.Roles')",
            :label-for="$t('label.roles')",
            :description="$t('message.worker_roles')"
          )
            v-select#roles(
              v-model="data.roles",
              :reduce="(roles) => roles.value",
              :multiple="true",
              label="title",
              :options="roles",
              :placeholder="$t('placeholder.roles')"
            )
        // Privileges
        b-col(md="6")
          b-form-group(
            :label="$t('label.Privileges')",
            :label-for="$t('label.privileges')",
            :description="$t('message.worker_privileges')"
          )
            validation-provider(:name="$t('label.Privileges')", rules="required", v-slot="{ errors }")
              v-select#privileges(
                v-model="data.privileges",
                :reduce="(privileges) => privileges.value",
                label="title",
                :options="privileges",
                :placeholder="$t('placeholder.privileges')"
              )
              small(class="text-danger") {{ errors[0] }}
      b-row
        b-col(md="12")
          // Supervisor
          b-form-group(
            :label="$t('label.Supervisors')",
            :label-for="$t('label.supervisors')",
            :description="$t('message.supervisor_desc')"
          )
            v-select#supervisor(
              v-model="data.supervisors",
              :reduce="(supervisors) => supervisors.value",
              :multiple="true",
              label="title",
              :options="supervisors",
              :placeholder="$t('placeholder.supervisors')"
            )

      b-row
        b-col.text-right(cols="12")
          b-button(variant="primary", :disabled="isSubmitting", @click="validateAndSubmit")
            | {{ $t('message.submit') }}
</template>

<script>
import vSelect from 'vue-select'
import { onMounted, ref, toRefs } from '@vue/composition-api/dist/vue-composition-api'
import useCommon from '../../useCommon'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import { useRouter } from '@core/utils/utils'

export default {
  name: 'Form',

  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
  },

  props: ['data', 'isSubmitting', 'isPCUploadVisible'],

  setup(props, { emit }) {
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const {
      getRolesForDropDown, roles, getSupervisorsForDropDown, supervisors, getLocationsForDropDown, locations, getPrivilegesForWorker, workerPrivileges,
    } = useCommon()
    const form = ref(null)
    const { route } = useRouter()
    const workerId = route.value.params.id

    const validateForm = () => new Promise((resolve, reject) => {
      if (!props.data.email && (props.data.privileges === 'consultant' || props.data.privileges === 'supervisor')) {
        showErrorMessage(i18n.t('message.emailRequiredForConsultantOrSupervisor'))
        reject()
      }
      form.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = () => {
      validateForm()
        .then(() => emit('submit', toRefs(props.data)))
        .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    onMounted(async () => {
      getRolesForDropDown()
      getSupervisorsForDropDown(null, workerId)
      getPrivilegesForWorker()
      getLocationsForDropDown()
    })

    return {
      roles,
      privileges: workerPrivileges,
      supervisors,
      locations,
      required,
      form,
      validateAndSubmit,
    }
  },
}
</script>

<style scoped>
</style>
