import { ref } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import { useRouter } from '@core/utils/utils'
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default function useEventsUpload() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { formatDatePicker } = useCommon()
  const { router } = useRouter()
  const { invokeFunction, getItems, createItems, deleteItems, ObjectId } = realmConnection()
  const projectStartDate = ref(new Date())
  const filterBy = ref("workers")
  const filterOptions = ref([
    { title: i18n.t('label.workers'), value: "workers" },
    { title: i18n.t('label.roles_locations'), value: "roles/locations" }
  ])
  const selectedWorkers = ref([])
  const selectedRoles = ref([])
  const selectedLocations = ref([])
  const includeWeekends = ref(false)
  const clientFunctionality = localStorage.getItem("clientData") ? JSON.parse(localStorage.clientData) : false

  const bulkEventsUpload = async (workers, pushRoute = true) => {
    const getUserData = store.state?.userStore?.userData
    const client_id = getUserData.client?.$oid
    const worker_id = getUserData.worker_id?.$oid
    const filterData = {
      workers,
      roles: [...selectedRoles.value],
      locations: [...selectedLocations.value],
    }

    // Adjust date format
    const formattedStartDate = formatDatePicker(projectStartDate.value)
    
    try {
      const workersWithRoles = await getWorkersRoles(filterBy.value, filterData, client_id)

      const rolesData = await getConfirmationsPlannings(client_id)
      
      const roleCycles = generateRoleCycles(rolesData)

      let workersIds = []
      let workersData = []

      for (const worker of workersWithRoles) {
        if (!worker.roles?.length) continue
        workersIds.push(worker._id)
        for (const role of worker.roles) {
          if (!roleCycles.hasOwnProperty(role)) {
            console.log(`Could not find role with ID ${role} in the list of roles`)
            continue
          }

          const personalCycle = generatePersonalCycle(roleCycles[role])

          workersData.push({
            ...worker,
            personalCycle,
          })
        }
      }

      await deleteFutureEvents(workersIds, formattedStartDate)

      await createEvents(workersData, client_id, worker_id, formattedStartDate)

      if (pushRoute) {
        if (clientFunctionality.default_view_pc_team) router.push({ name: 'apps-pc-team' })
        else if (clientFunctionality.default_view_daily_dialogue) router.push({ name: 'habit-meeting-new' })
        else router.push({ name: 'apps-calendar' })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getWorkersRoles = (filterBy, filterData, clientId) => {
    let conditions
    if (filterBy === "workers") {
      conditions = { _id: { $in: filterData.workers.map(e => ObjectId(e)) } }
    } else {
      conditions = {
        client_id: ObjectId(clientId),
        deleted: { $ne: true },
      }

      if (filterData.locations.length > 0) {
        conditions.locations = { $in: filterData.locations.map(e => ObjectId(e)) }
      }

      if (filterData.roles.length > 0) {
        conditions.roles = { $in: filterData.roles.map(e => ObjectId(e)) }
      }
    }

    return new Promise(async (resolve, reject) => {
      try {
        const items = await getItems({ collection: 'worker', query: conditions, options: { projection : { roles: 1, supervisors: 1 } } })
  
        if (!items) {
          showErrorMessage(i18n.t('message.err_no_employees_found'))
          reject("Employees not found")
        }

        resolve(items)
      } catch (error) {
        showErrorMessage(i18n.t('message.err_worker_list'));
        reject(error)
      }
    })
  }

  const getConfirmationsPlannings = async (client_id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const input = { client_id }
      
        const items = await invokeFunction({ name: 'confirmationPlanningGroupByRole', arg: input })
  
        if (!items?.rolesData?.length) {
          showErrorMessage(i18n.t('message.err_empty_confirmation_planning'))
          reject("Confirmations plannings not found")
        }
  
        resolve(items.rolesData)
      } catch (error) {
        showErrorMessage(i18n.t('message.err_confirmation_planning'))
        reject(error)
      }
    })
  }

  const generateRoleCycles = (rolesData) => {
    let roleCycles = {}
    rolesData.forEach(role => {
      const lengthIteration = role.planning[0].confirmationsPerYear
      let cycle = []
      for (let i = 0; i < lengthIteration; i++) {
        role.planning.forEach(plan => {
          if (plan.confirmationsPerYear > 0) {
            cycle.push(plan.process)
            plan.confirmationsPerYear--
          }
        })
      }
      roleCycles[role._id] = cycle
    })
    return roleCycles
  }

  const generatePersonalCycle = (roleCycle) => {
    const cycleLength = roleCycle.length
    const index = Math.floor(Math.random() * cycleLength)
    const firstPart = roleCycle.slice(0, index)
    const secondPart = roleCycle.slice(index, cycleLength)
    const personalCycle = [...secondPart, ...firstPart]
    return personalCycle
  }

  const deleteFutureEvents = async (workersIds, projectStartDate) => {
    return new Promise(async (resolve, reject) => {
      try {
        const query = {
          start: { $gte: projectStartDate },
          'extendedProps.calendar': { $ne: "Realizadas" },
          attendee: { $in: workersIds }
        }
  
        const { deletedCount } = await deleteItems({ collection: 'event', query })
  
        showSuccessMessage(`${deletedCount} ${i18n.t('message.old_events_deleted')}`)
        resolve()
      } catch (error) {
        showErrorMessage(i18n.t('message.err_deleting_old_events'))
        reject(error)
      }
    })
  }

  const createEvents = (workersData, client_id, worker_id, projectStartDate) => {
    let events = []

    let now = new Date()
    let firstDayOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1)
    now = now.getTime()
    firstDayOfNextMonth = firstDayOfNextMonth.getTime()

    const projectStartYear = projectStartDate.getFullYear()
    const projectStartMonth = projectStartDate.getMonth()
    const projectStartDay = projectStartDate.getDate()

    workersData.forEach(worker => {
      const cycleLength = worker.personalCycle.length
      const monthlyEvents = cycleLength === 12
      const timeSpan = Math.floor(365 / cycleLength)
      const eventDay = Math.floor(Math.random() * 31 + 1)
      const cycleStartMonth = eventDay >= projectStartDay ? projectStartMonth : projectStartMonth + 1

      for (let i = 0; i < cycleLength; i++) {
        const processId = worker.personalCycle[i]
        let eventStartDate = monthlyEvents
          ? new Date(projectStartYear, cycleStartMonth + i, eventDay, 12)
          : new Date(projectStartYear, cycleStartMonth, eventDay + i * timeSpan, 12)

        // Check month
        let correctMonth
        if (monthlyEvents) {
          correctMonth = (cycleStartMonth + i) % 12
          if (monthlyEvents && correctMonth !== eventStartDate.getMonth()) eventStartDate.setDate(0)
        }

        // Check weekend
        if (!includeWeekends.value) {
          const dayOfWeek = eventStartDate.getDay()
          if (dayOfWeek === 0) {
            eventStartDate.setDate(eventStartDate.getDate() + 1)
            if (monthlyEvents && correctMonth !== eventStartDate.getMonth()) eventStartDate.setDate(-2)
          }
          else if (dayOfWeek === 6) {
            eventStartDate.setDate(eventStartDate.getDate() - 1)
            if (monthlyEvents && correctMonth !== eventStartDate.getMonth()) eventStartDate.setDate(eventStartDate.getDate() + 3)
          }
        }

        let eventEndDate = new Date(eventStartDate)
        eventEndDate.setMinutes(30)
  
        const eventData = {
          client_id: ObjectId(client_id),
          process: processId,
          attendee: worker._id,
          organizer: worker.supervisors?.length ? worker.supervisors[0] : ObjectId(worker_id),
          start: eventStartDate,
          end: eventEndDate,
          extendedProps: {
            calendar: setEventStatus(now, firstDayOfNextMonth, eventStartDate.getTime()),
          },
        }
        
        events.push(eventData)
      }
    })

    // Upload events
    return new Promise(async (resolve, reject) => {
      try {
        let eventsCreated = 0

        if (events.length) {
          const { insertedIds } = await createItems({ collection: 'event', payload: events })
          
          eventsCreated = insertedIds?.length || 0
        }
        
        showSuccessMessage(`${eventsCreated} ${i18n.t('message.new_events_created')}`)
        resolve()
      } catch (error) {
        showErrorMessage(i18n.t('message.err_creating_new_events'))
        reject(error)
      }
    })
  }

  const setEventStatus = (now, firstDayOfNextMonth, eventStart) => {
    if (eventStart >= firstDayOfNextMonth) return "Futuras"
    if (eventStart < now) return "Vencidas"
    return "Pendientes"
  }

  return {
    bulkEventsUpload,
    projectStartDate,
    filterBy,
    filterOptions,
    selectedWorkers,
    selectedRoles,
    selectedLocations,
    includeWeekends,
  }
}
