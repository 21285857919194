import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'
import useEventsUpload from "@/views/organization/confirmation-planning/events-upload/useEventsUpload"
import realmConnection from '@/views/habit/realm'

export default function useWorkerAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { bulkEventsUpload } = useEventsUpload()
  const { createItem, updateItem, ObjectId } = realmConnection()
  const userData = store.state?.userStore?.userData
  const { massive_pc_upload_default } = JSON.parse(localStorage.getItem('clientData') || '{}')
  const worker = ref({ privileges: "employee", isPCUploadEnabled: massive_pc_upload_default || false })
  const isSubmitting = ref(false)
  const collection = 'worker'

  const create = async data => {
    isSubmitting.value = true

    const payload = {
      client_id: ObjectId(userData.client.$oid)
    }

    if (data.name?.value) payload.name = data.name.value
    if (data.email?.value) payload.email = data.email.value
    if (data.supervisors?.value) payload.supervisors = data.supervisors.value.map(e => ObjectId(e)) || []
    if (data.locations?.value) payload.locations = data.locations.value.map(e => ObjectId(e)) || []
    if (data.roles?.value) payload.roles = data.roles.value.map(e => ObjectId(e)) || []
    if (data.privileges?.value) payload.privileges = data.privileges?.value

    const role = data.privileges?.value || ''
    const defaultPassword = '12345678'

    try {
      const { insertedId } = await createItem({ collection, payload })

      if (role === 'consultant' || role === 'supervisor') {
        await useJwt.register({ email: payload.email, password: defaultPassword, name: payload.name })
        const logUser = await useJwt.login({ email: payload.email, password: defaultPassword })
        
        const userPayload = {
          "custom_data.username": payload.name,
          "custom_data.role": role,
          "custom_data.worker_id": insertedId,
          "custom_data.ability": [ { action: 'manage', subject: 'all' } ],
        }
        const action = { $set: userPayload }
        await updateItem({ collection: 'user', query: { id: logUser.id }, action })

        await useJwt.realm.allUsers[logUser.id].logOut()
      }

      showSuccessMessage(i18n.t('message.worker_added'))

      // Bulk P.C. (events) Upload
      if (data.isPCUploadEnabled?.value) await bulkEventsUpload([insertedId], false)
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.worker_error'))
    } finally {
      router.push({ name: 'organization-worker-list' })
      isSubmitting.value = false
    }
  }

  return {
    create,
    worker,
    isSubmitting,
  }
}
